// Fonts
//$text-font-family: Merriweather, Georgia, serif;
$text-font-family: 'IBM Plex Sans', sans-serif;
$heading-font-family: 'IBM Plex Sans', sans-serif;
$code-font-family: 'Source Code Pro', 'Lucida Console', monospace;

// Colors
$bg-color: #FAFAFA !default;
$fg-color: #212121 !default;
$alt-bg-color: #E0E0E0 !default;
$alt-fg-color: #000 !default;
$link-color: #0aa2a2 !default;

// Colors dark
$bg-color-dark: #212121 !default;
$fg-color-dark: #dadada !default;
$alt-bg-color-dark: #424242 !default;
$alt-fg-color-dark: #dadada !default;
$link-color-dark: #36679f !default;
